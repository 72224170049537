import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useLocalStorage } from "@hooks/useLocalStorage"
import { useProductIds } from "@hooks/useProductIds"
import { AddingNotify, DeleteNotify } from "@helpers/Toastify"

type ShoppingCartProviderProps = {
  children: ReactNode
}

type CartItem = {
  product: number | string,
  pack?: number | string
  quantity: number,
  price: number,
  originPrice?: number,
  itemtype?: string
  isvariant?: boolean
  variant?: number | string,
  ukey?: number | string,
  marque?: string;
}

interface OrderContextType {
  paymentMethod: string;
  totalPrice: number;
  discount: number;
  discountAmount: number;
}

type ShoppingCartContext = {
  getItemQuantity: (product: number | string) => number
  incrementCartQuantity: (product: number | string, price: number, itemKey: number | string | undefined) => void
  decrementCartQuantity: (product: number | string, itemKey: number | string | undefined) => void
  removeFromCart: (product: number | string, itemKey: number | string | undefined) => void
  addToCart: (
    product: number | string,
    price: number,
    itemtype?: string,
    variant?: number | string,
    quantity?: number,
    originPrice?: number,
    isvariant?: boolean,
    marque?: string) => void,

  emptyShoppingCart: () => void
  cartQuantity: number
  cartItems: CartItem[]
  total: number
  orderData: OrderContextType
  setOrderData: React.Dispatch<React.SetStateAction<OrderContextType>>
  updateCartItemsWithPromotions: () => void,
  getItemCountByMarque: (marque: string) => number;
}

const ShoppingCartContext = createContext({} as ShoppingCartContext)

export function useShoppingCart() {
  return useContext(ShoppingCartContext)
}

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [cartItems, setCartItems] = useLocalStorage<CartItem[]>(
    "shopping-cart",
    []
  )
  const [orderData, setOrderData] = useState<OrderContextType>({
    paymentMethod: '',
    totalPrice: 0,
    discount: 0,
    discountAmount: 0
  });

  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )

  const { productIds, isProductIds } = useProductIds();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const newTotal = cartItems.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0
    );
    setTotal(newTotal);
  }, [cartItems]);


  function getItemQuantity(product: number | string) {
    return cartItems.find(item => item.product === product)?.quantity || 0
  }

  function getItemCountByMarque(marque: string): number {
    return cartItems
      .filter((item) => item.marque === marque)
      .reduce((total, item) => total + item.quantity, 0);
  }

  function addToCart(
    product: number | string,
    price: number,
    itemtype?: string,
    variant?: number | string,
    quantity: number = 1,
    originPrice?: number,
    isvariant?: boolean,
    marque?: string) {
    setCartItems((currItems: any[]) => {
      const ukey = `${product}-${variant || ''}`;
      const existingItem = currItems.find((item) => item.ukey === ukey);

      if (existingItem) {
        return currItems.map((item) =>
          item.ukey === ukey
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        if (variant) {
          return [
            ...currItems,
            { product, price, itemtype, quantity, originPrice, variant, ukey, isvariant, marque },
          ];
        } else {
          return [
            ...currItems,
            { product, price, itemtype, quantity, originPrice, ukey, marque },
          ];
        }
      }
    });
    AddingNotify(`${typeof product === "string" ? "Pack" : "Produit"}`, 1500);
  }

  function incrementCartQuantity(product: number | string, price: number, itemKey: number | string | undefined) {
    setCartItems(currItems => {
      if (currItems.find(item => item.ukey === itemKey) == null) {
        return [...currItems, { product, quantity: 1, price }]
      } else {
        return currItems.map(item => {
          if (item.ukey === itemKey) {
            return { ...item, quantity: item.quantity + 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function decrementCartQuantity(product: number | string, itemKey: number | string | undefined) {
    setCartItems(currItems => {
      if (currItems.find(item => item.ukey === itemKey)?.quantity === 1) {
        return currItems.filter(item => item.ukey !== itemKey)
      } else {
        return currItems.map(item => {
          if (item.ukey === itemKey) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })
      }
    })
  }

  function removeFromCart(product: number | string, itemKey: number | string | undefined) {
    const pType = typeof (product);

    setCartItems(currItems => {
      return currItems.filter(item => item.ukey !== itemKey)
    })
    DeleteNotify(`${pType === 'string' ? 'Pack' : 'Produit'}`, 1500);
  }

  function emptyShoppingCart() {
    setCartItems([]);
  }

  const updateCartItemsWithPromotions = () => {
    if (isProductIds) {
      const updatedCartItems = cartItems.map((item) => {
        const productHasPromotion = productIds.includes(Number(item.product));
        if (!productHasPromotion && item.itemtype === 'product') {
          return {
            ...item,
            price: item.originPrice || item.price,
          };
        }
        return item;
      });
      setCartItems(updatedCartItems);
    }
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        addToCart,
        incrementCartQuantity,
        decrementCartQuantity,
        removeFromCart,
        emptyShoppingCart,
        cartItems,
        cartQuantity,
        total,
        orderData,
        setOrderData,
        updateCartItemsWithPromotions,
        getItemCountByMarque
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}