import React, { Suspense, useEffect, useState, lazy } from 'react'
import useApiRequest from "@mbs-dev/api-request";
import Loading from '@components/Loading/Loading';
import { Helmet } from 'react-helmet-async';
import AOS from 'aos';
import 'aos/dist/aos.css';
import MarquesList from '@components/MarquesList/MarquesList';
import BlogSwiper from '@components/Carousel/BlogSwiper/BlogSwiper';
import { apiUrl } from '@helpers/Helpers';
import { Footer, Navbar, TopHeader } from '@helpers/Index';
import HomeCarousel from '@components/Carousel/HomeCarousel/HomeCarousel';
import NewProducts from '@components/NewProducts/NewProducts';
import PopularePacks from '@components/PopularePacks/PopularePacks';
import PopulareProducts from '@components/PopulareProducts/PopulareProducts';
import { CategorieProps, SliderHomeProps } from '@helpers/Props';
import { useSliderContext } from '@contexts/SliderContext';
import { useCategoriesContext } from '@contexts/CategoriesContext';
import Layout from '@layouts/Layout';


const Home: React.FC = () => {
    // const [sliders, setSliders] = useState<SliderHomeProps[]>([]);
    // const [isLoading, setIsLoading] = useState(true);
    const { sliders, isFetching } = useSliderContext();
    const { categories } = useCategoriesContext()

    // const { apiRequest } = useApiRequest();

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 300);
    // }, []);

    // const fetchData = async () => {
    //     try {
    //         const response = await apiRequest({
    //             route: `${apiUrl}/sliders.json`,
    //             method: 'GET',
    //         });

    //         setSliders(response.data)
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    // useEffect(() => {
    //     fetchData();
    // }, [])


    useEffect(() => {
        AOS.init();
    }, [])


    return (

        <Layout>
            {/* <> */}
            <Helmet>
                <title>Beauty Hub : Meilleures marques de cosmétiques</title>
                <meta name="description" content="Découvrez des produits de beauté premium chez Beautyhub. Achetez des marques de premier plan comme Absolute New York, Nicka K New York, Urban Care et Camaleona. Élevez votre routine beauté avec nos cosmétiques de haute qualité." />
                <meta name="keywords" content={`Beautyhub, Beauty hub, cosmétiques, Absolute New York, Nicka K New York, Urban Care, Camaleona, produits de beauté, soins de la peau, maquillage, soins capillaires, ${categories.map((item: CategorieProps) => (`${item.title}`))}`} />
                <meta name="author" content="Beauty Hub" />
                <meta property="og:image" content="%PUBLIC_URL%/media/logo/logo-beautyhub.png" />
                <meta property="og:url" content="https://beautyhub.ma" />
                <meta property="og:title" content="Beauty Hub : Meilleures marques de cosmétiques" />
                <meta property="og:description" content="Découvrez des produits de beauté premium chez Beautyhub. Achetez des marques de premier plan comme Absolute New York, Nicka K New York, Urban Care et Camaleona. Élevez votre routine beauté avec nos cosmétiques de haute qualité." />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Beauty Hub" />
                <meta property="og:locale" content="fr_FR" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Beauty Hub : Meilleures marques de cosmétiques" />
                <meta name="twitter:description"
                    content="Découvrez des produits de beauté premium chez Beautyhub. Achetez des marques de premier plan comme Absolute New York, Nicka K New York, Urban Care et Camaleona. Élevez votre routine beauté avec nos cosmétiques de haute qualité." />
                <meta name="twitter:image" content="%PUBLIC_URL%/media/logo/logo-beautyhub.png" />
                <meta name="twitter:url" content="https://www.beautyhub.ma" />
                <link rel="canonical" href="https://www.beautyhub.ma" />
            </Helmet>
            {/* {isLoading ? (
    <Loading />
) : (
    <Suspense fallback={<Loading />}> */}
            {/* <TopHeader />
            <Navbar /> */}
            {/* <HomeCarousel items={sliders} /> */}
            <HomeCarousel items={sliders} isLoading={isFetching} />
            {/* <Marques /> */}
            {/* <PopulareProducts /> */}
            {/* <PopularePacks /> */}
            {/* <div data-aos="fade-up" data-aos-duration="2000">
            <Marques />
        </div> */}
            <div data-aos="fade-up" data-aos-duration="2000">
                <MarquesList />
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
                <NewProducts />
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
                <PopulareProducts />
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
                <PopularePacks />
            </div>

            {/* <div data-aos="fade-up" data-aos-duration="2000">
            <BlogMultiCarousel  />
        </div> */}

            {/* <div data-aos="fade-up" data-aos-duration="2000">
            <BlogSwiper  />
        </div> */}

            {/* <div data-aos="fade-up" data-aos-duration="2000">
            <CategoriesOld />
        </div> */}
            {/* <div data-aos="fade-up" data-aos-duration="2000">
            <Categories />
        </div> */}
            {/* {!isFetching ?
                <Footer />
                :
                null
            } */}
            {/* </Suspense>
)} */}
            {/* </> */}

        </Layout>

    )
}

export default Home;