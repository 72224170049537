import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

export function useLocalStorage<T>(key: string, initialValue: T | (() => T)) {
  const secretKey = process.env.REACT_APP_SECRET_KEY || '9!dF1&wA7#vE9!oF6&wZ9!tX3@uV1#gC5&a';

  const [value, setValue] = useState<T>(() => {
    const encryptedValue = localStorage.getItem(key);
    if (encryptedValue != null) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedValue);
      } catch (error) {
        console.error('Error decrypting localStorage value', error);
        localStorage.removeItem(key);
        if (typeof initialValue === "function") {
          return (initialValue as () => T)();
        } else {
          return initialValue;
        }
      }
    } else {
      if (typeof initialValue === "function") {
        return (initialValue as () => T)();
      } else {
        return initialValue;
      }
    }
  });

  useEffect(() => {
    try {
      const stringValue = JSON.stringify(value);
      const encryptedValue = CryptoJS.AES.encrypt(stringValue, secretKey).toString();
      localStorage.setItem(key, encryptedValue);
    } catch (error) {
      console.error('Error encrypting localStorage value', error);
    }
  }, [key, value]);

  return [value, setValue] as [typeof value, typeof setValue];
}
