import { useEffect, useState } from 'react'
import { OrderItem } from '@components/OrderItem/OrderItem'
import { useNavigate } from 'react-router-dom'
import useApiRequest from "@mbs-dev/api-request";
import { FormatDate, ReactHelmet, apiUrl, paysRegion, referenceGenerator } from '@helpers/Helpers'
import { AddingNotify, FrCustomeErrorNorify, InfoNotify, notify } from '@helpers/Toastify'
import { AuthUserProps, UserAdresseProps } from '@helpers/Props'
import { HeaderContainer, SubmitButton, formHelper, loadingHelper } from '@helpers/Index'
import { initOrderAdresse } from '@helpers/FormikHelper'
import { OrderAdresseSchema } from '@helpers/YupHelper'
import Layout from '../../layouts/Layout'
import './CheckoutStyle.css'
import { useShoppingCart } from '@contexts/ShoppingCartContext'

const Checkout: React.FC = () => {

    const user_token = localStorage.getItem('bh_user_token')
    const navigate = useNavigate()
    localStorage.removeItem('isguarded')

    useEffect(() => {
        if (!user_token) {
            navigate('/login')
            InfoNotify('Vous devrez être connecté pour effectuer cette action!')
        }
        if (cartQuantity < 1) {
            navigate('/boutique')
            InfoNotify('Votre panier est actuellement vide!')
        }
    }, [user_token, navigate])

    const [authUser, setAuthUser] = useState<AuthUserProps | null>(null)
    const storedUser = localStorage.getItem('bh_user')

    useEffect(() => {
        if (storedUser) {
            setAuthUser(JSON.parse(storedUser))
        }
    }, [])

    const { cartItems, cartQuantity, orderData, emptyShoppingCart } = useShoppingCart()
    const [newAdresse, setNewAdresse] = useState(false)
    const [validateAdresse, setValidateAdresse] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingAdresse, setIsLoadingAdresse] = useState(false)
    const [livraisonAdresse, setLivraisonAdresse] = useState('')
    const [facturationAdresse, setFacturationAdresse] = useState('')
    const [FAdresseInfo, setFAdresseInfo] = useState<UserAdresseProps>()
    const [orderNote, setOrderNote] = useState('')
    const [tryCounter, setTryCounter] = useState<number>(0)
    const [isSended, setIsSended] = useState(false)
    const [isOrderPassed, setIsOrderPassed] = useState(false)

    const MAX_RETRIES = 3
    const { apiRequest } = useApiRequest();
    let adresseType = ""
    let desabled = false
    const user = `api/users/${authUser?.id}`

    !newAdresse ? adresseType = 'livraison' : adresseType = 'newlivraison'
    validateAdresse ? desabled = true : desabled = false

    const handleError = () => {
        setIsLoading(false)
        FrCustomeErrorNorify()
    }

    const formik = formHelper.useFormik({
        initialValues: initOrderAdresse,
        validationSchema: OrderAdresseSchema,
        onSubmit: async (values) => {
            const data = { ...values, user, adresseType }

            if (adresseType === 'livraison') {
                notify('Adresse validé avec succès')
                setValidateAdresse(true)
            }

            else if (adresseType === 'newlivraison') {
                const response = await apiRequest({
                    route: `${apiUrl}/user_adresses`,
                    method: 'POST',
                    data: data,
                    requiresAuth: true,
                    token: `${localStorage.getItem('bh_user_token')}`,
                })

                if (response.status === 201) {
                    notify('Adresse validé avec succès')
                    setLivraisonAdresse(`api/user_adresses/${response.data.id}`)
                    setValidateAdresse(true)
                }
            }
        },
    })

    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setValues
    } = formik

    const handleNewAdresseChange = () => {
        setNewAdresse(!newAdresse)
    }

    const fetchData = async () => {
        try {
            setIsLoadingAdresse(true)
            if (authUser?.id) {
                const response = await apiRequest({
                    route: `${apiUrl}/user_adresses/user_id/${authUser?.id}`,
                    method: 'GET',
                    requiresAuth: true,
                    token: `${localStorage.getItem('bh_user_token')}`,
                })

                if (response.data?.livraisonAdresse[0] === undefined || response.data?.facturationAdresse[0] === undefined) {
                    navigate('/my-account/edit-address')
                    InfoNotify('Veuillez ajouter votre adresses')
                }

                else {
                    await setValues({
                        nom: response.data.livraisonAdresse[0].nom,
                        prenom: response.data.livraisonAdresse[0].prenom,
                        pays: paysRegion,
                        rueInfo: response.data.livraisonAdresse[0].rueInfo,
                        ville: response.data.livraisonAdresse[0].ville,
                        region: response.data.livraisonAdresse[0].region,
                        // codepostal: response.data.livraisonAdresse[0].codepostal,
                        telephone: response.data.livraisonAdresse[0].telephone,
                        email: response.data.livraisonAdresse[0].email,
                    })

                    setIsLoadingAdresse(false)
                    setFAdresseInfo(response.data.facturationAdresse[0])
                    setLivraisonAdresse(`api/user_adresses/${response.data.livraisonAdresse[0].id}`)
                    setFacturationAdresse(`api/user_adresses/${response.data.facturationAdresse[0].id}`)
                }

            }
        } catch {

        }
    }

    useEffect(() => {
        fetchData()
    }, [authUser?.id])

    const transformData = async (data: any[]) => {
        const packIdsArray = await Promise.all(
            data.map(async (item) => {
                const { product, pack, ...rest } = item
                let uniqId = typeof product === 'string' ? product : null

                if (uniqId !== null) {
                    const response = await apiRequest({
                        route: `${apiUrl}/pack-id/uniq_id/${product}`,
                        method: 'GET',
                        requiresAuth: true,
                        token: `${localStorage.getItem('bh_user_token')}`,

                    })

                    if (response.status === 200) {
                        return response.data.packId
                    }
                }

                return null
            })
        )

        const transformedData = data.map((item: any, index: any) => {
            const { product, variant, isvariant, ...rest } = item
            const packId = typeof product === 'string' ? packIdsArray[index] : null

            const transformedItem: any = {
                ...(typeof product === 'number' ? { product: `api/products/${product}` } : {}),
                ...(isvariant && variant ? { variant: `api/variants/${variant}` } : {}),
                ...(typeof product === 'string' && packId !== null ? { pack: `api/packs/${packId}` } : {}),
                isvariant,
                ...rest,
            }

            return transformedItem
        })

        return transformedData
    }

    const valider = async (currentTryCounter: number = 0) => {
        setIsLoading(true)

        const checkLastOrderResponse = await apiRequest({
            route: `${apiUrl}/last-order`,
            method: 'GET',
            requiresAuth: true,
            token: `${localStorage.getItem('bh_user_token')}`,
        })

        if (checkLastOrderResponse.status === 200) {
            const totalPrice = orderData.totalPrice
            const discount = orderData.discount > 0 ? orderData.discount : null
            const discountAmount = orderData.discountAmount > 0 ? orderData.discountAmount : null
            const itemsQuantity = cartItems.length
            const paymentMethod = orderData.paymentMethod
            const orderStatus = 'pending'
            const paymentStatus = 'pending'
            const reference = `${referenceGenerator()}${checkLastOrderResponse.data.lastOrderId + 1}`

            const userEmail = new FormData()
            userEmail.append('email', String(FAdresseInfo?.email))

            let orderItems = await transformData(cartItems)

            const data = {
                facturationAdresse,
                livraisonAdresse,
                totalPrice,
                discount,
                discountAmount,
                itemsQuantity,
                orderNote,
                paymentMethod,
                paymentStatus,
                orderItems,
                orderStatus,
                reference,
                dateCreate: FormatDate(new Date()),
                user
            }

            if (paymentMethod === 'livraison') {
                try {
                    const response = await apiRequest({
                        route: `${apiUrl}/orders`,
                        method: 'POST',
                        data: data,
                        requiresAuth: true,
                        token: `${localStorage.getItem('bh_user_token')}`,
                    })

                    if (response.status === 201) {
                        setIsOrderPassed(true)
                        AddingNotify('Commmane')
                        navigate('/my-account/orders')
                        emptyShoppingCart()
                        setIsLoading(false)

                        apiRequest({
                            route: `${apiUrl}/admin-order-email`,
                            method: 'POST',
                            data: userEmail,
                            requiresAuth: true,
                            token: `${localStorage.getItem('bh_user_token')}`,
                        })
                    }

                } catch {
                    handleError()

                    // setTryCounter(currentTryCounter + 1)
                    // if (currentTryCounter < MAX_RETRIES) {
                    //     valider(currentTryCounter + 1)
                    // } else {
                    //     FrCustomeErrorNorify()
                    //     setIsLoading(false)
                    // }
                }

            } else if (paymentMethod === 'carte') {
                try {
                    const userEmail = new FormData()
                    userEmail.append('email', String(FAdresseInfo?.email))

                    const orderInfo = {
                        userEmail: FAdresseInfo?.email,
                        reference: reference
                    }

                    localStorage.setItem('orderInfo', JSON.stringify(orderInfo))

                    //----------- Start CMI Form
                    const cmiForm = new FormData()
                    const billToStreet = `${FAdresseInfo?.rueInfo}, ${FAdresseInfo?.ville}, ${FAdresseInfo?.region}`

                    cmiForm.append('user', String(authUser?.id))
                    cmiForm.append('BillToStreet', billToStreet)
                    cmiForm.append('totalPrice', String(totalPrice))
                    cmiForm.append('reference', String(reference))
                    cmiForm.append('itemsQuantity', String(itemsQuantity))
                    cmiForm.append('email', String(FAdresseInfo?.email))
                    cmiForm.append('telephone', String(FAdresseInfo?.telephone))
                    //----------- End CMI Form

                    try {
                        const response = await apiRequest({
                            route: `${apiUrl}/orders`,
                            method: 'POST',
                            data: data,
                            requiresAuth: true,
                            token: `${localStorage.getItem('bh_user_token')}`,
                        })

                        if (response.status === 201) {
                            emptyShoppingCart()

                            // await apiRequest({
                            //     route: `${apiUrl}/admin-order-email`,
                            //     method: 'POST',
                            //     body: userEmail
                            // });

                            // Start CMI Request
                            const cmiResponse = await apiRequest({
                                route: `${apiUrl}/payments/cmi`,
                                method: 'POST',
                                data: cmiForm,
                                requiresAuth: true,
                                token: `${localStorage.getItem('bh_user_token')}`,
                            })
                            if (cmiResponse.status === 200) {
                                const { url, data } = cmiResponse.data
                                const form = document.getElementById("cmiPaymentForm") as HTMLFormElement
                                form.method = "POST"
                                form.action = url
                                Object.keys(data).forEach((name) => {
                                    var input = document.createElement("input")
                                    input.name = name
                                    input.value = data[name]
                                    form.appendChild(input)
                                })

                                form.submit()

                            } else {
                                handleError()
                            }
                            // End CMI Request
                        }
                    } catch {
                        handleError()

                        // setTryCounter(currentTryCounter + 1)
                        // if (currentTryCounter < MAX_RETRIES) {
                        //     valider(currentTryCounter + 1)
                        // } else {
                        //     FrCustomeErrorNorify()
                        //     setIsLoading(false)
                        // }
                    }
                } catch {
                    handleError()
                }
            }

        } else {
            handleError()
        }
    }

    return (
        <Layout>
            <ReactHelmet title='Checkout' />

            <div className="shopping-card d-flex align-items-center">
                <div className="container">
                    <HeaderContainer className='fw-bold fs-1 mt-5' title="Checkout" />

                    <div className="row">
                        <div className="col-md-12 col-lg-7 checkout-card-items mb-4">
                            <div className="adresse-items">
                                <form action="" onSubmit={handleSubmit}>
                                    <div className="edit-form">
                                        <div className="row d-flex-start">
                                            <h4 className='text-start mb-4 mt-2 clr-blue' >Adresse de livraison</h4>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Nom *"
                                                    spanValue="Nom "
                                                    name="nom"
                                                    type="text"
                                                    value={values.nom}
                                                    className={`${touched.nom && errors.nom ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Prenom *"
                                                    spanValue="Prenom "
                                                    name="prenom"
                                                    type="text"
                                                    value={values.prenom}
                                                    className={`${touched.prenom && errors.prenom ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Numéro et nom de rue *"
                                                    spanValue="Numéro et nom de rue"
                                                    name="rueInfo"
                                                    type="text"
                                                    value={values.rueInfo}
                                                    className={`${touched.rueInfo && errors.rueInfo ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Ville *"
                                                    spanValue="Ville"
                                                    name="ville"
                                                    type="text"
                                                    value={values.ville}
                                                    className={`${touched.ville && errors.ville ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Région *"
                                                    spanValue="Région"
                                                    name="region"
                                                    type="text"
                                                    value={values.region}
                                                    className={`${touched.region && errors.region ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            {/* <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Code postal *"
                                                    spanValue="Code postal"
                                                    name="codepostal"
                                                    type="text"
                                                    value={values.codepostal}
                                                    className={`${touched.codepostal && errors.codepostal ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div> */}

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Telephone *"
                                                    spanValue="Telephone"
                                                    name="telephone"
                                                    type="text"
                                                    value={values.telephone}
                                                    className={`${touched.telephone && errors.telephone ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Email *"
                                                    spanValue="Email"
                                                    name="email"
                                                    type="text"
                                                    value={values.email}
                                                    className={`${touched.email && errors.email ? "is-invalid" : ""}`}
                                                    touched={touched}
                                                    errors={errors}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mb-4">
                                                <formHelper.FormInput
                                                    label="Notes de commande (facultatif)"
                                                    spanValue="Notes de commande (facultatif)"
                                                    name="note"
                                                    textarea={true}
                                                    rows={6}
                                                    value={orderNote}
                                                    handleChange={(e) => setOrderNote(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12 mt-2 d-flex-start">
                                                {isLoadingAdresse
                                                    ?
                                                    <loadingHelper.DottedLoading />
                                                    :
                                                    <SubmitButton
                                                        className="mt-2 px-2rem py-14px"
                                                        btnLabel={`${newAdresse ? "Valider le nouveau adresse" : "Valider L'adresse"}`}
                                                        disabled={desabled}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="form-check mt-5 mb-4 ms-1">
                                <input
                                    className="custom-form-check form-check-input shopping-form-check-input"
                                    type="checkbox"
                                    name="payment"
                                    id="newAdresse"
                                    onChange={handleNewAdresseChange}
                                />
                                <label className="form-check-label fs-18 ff-varela-round fw-bold ms-1" htmlFor="newAdresse">
                                    Expédier à une adresse différente ?
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-5">
                            <div className="shopping-cart-checkout">

                                <div className="row">
                                    <div className="shopping-cart-checkout-soustotal-title ff-varela-round fw-bold fs-5 mb-1">Articles de commande :</div>
                                    <hr className='hr-tag' />
                                    <div className="col-md-12 col-lg-12 checkout-items">
                                        <div className="checkout-items-centent">
                                            {cartItems.map(item => (
                                                <OrderItem key={item.product} {...item} price={item.price} itemKey={Number(item.ukey)} />
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    <hr className='hr-tag' />

                                    <div className="row">
                                        <div className="shopping-cart-checkout-total d-flex justify-content-between px-5 mb-5">
                                            <div className="shopping-cart-checkout-total-title px-">TOTAL</div>
                                            <div className="shopping-cart-checkout-total-value">{orderData.totalPrice.toFixed(2)} DH</div>
                                        </div>
                                        <div className="row d-flex justify-content-start">
                                            <div className="col-12 text-start">
                                                {isLoading ?
                                                    <loadingHelper.DottedLoading />
                                                    :
                                                    <SubmitButton
                                                        className="mt-1 px-2rem py-14px"
                                                        btnLabel="Valider la commande"
                                                        onClick={() => valider()}
                                                        disabled={!desabled}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-none'>
                <form name='cmiPaymentForm' id="cmiPaymentForm"></form>
            </div>
        </Layout>
    )
}

export default Checkout