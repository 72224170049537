import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import useApiRequest from "@mbs-dev/api-request";
import { FormatDate, apiUrl, handleScrollTop } from '@helpers/Helpers';
import { Link, useNavigate } from 'react-router-dom';
import { notify } from '@helpers/Toastify';
import { AuthUserProps } from '@helpers/Props';
import { formHelper, SubmitButton, loadingHelper } from '@helpers/Index';
import { contactSchema } from '@helpers/YupHelper';
import './ContactStyle.css'
import Layout from '@layouts/Layout';
import Icons from '@helpers/IconsHelper';




const Contact: React.FC = () => {

    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const user_token = localStorage.getItem('bh_user_token');
    const storedUser = localStorage.getItem('bh_user');
    const [user, setUser] = useState<AuthUserProps | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }

    }, [user_token, storedUser, navigate]);

    useEffect(() => {
        handleScrollTop()

    }, [user_token, storedUser, navigate]);

    const userId = `api/users/${user?.id}`;



    const formik = formHelper.useFormik({
        initialValues: {
            name: "",
            email: "",
            subject: "",
            message: "",
        },
        validationSchema: contactSchema,
        onSubmit: async (values, { resetForm }) => {

            const data = { ...values, user: userId, dateCreate: FormatDate(new Date()), getResponse: 0 }

            setIsLoading(true)
            const response = await apiRequest({
                route: `${apiUrl}/user_emails`,
                method: 'POST',
                data: data,
                requiresAuth: true,
                token: `${localStorage.getItem('bh_user_token')}`,
            });

            if (response.status === 201) {
                notify('Message envoyé');
                setIsLoading(false);
                resetForm()
            }

        },
    });

    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
    } = formik;

    return (
        <Layout>
            <Helmet>
                <title>Beauty Hub - Contact</title>
            </Helmet>

            <div className="contact-top mt-3 ox-hidden">
                <div className="contact-top-image">

                </div>

                <div className="contact-top-cards d-flex-center">
                    <div className="row d-flex-center">

                        <div className="col-lg-4 col-md-4 col-sm-4 col-12 d-flex-center flex-wrap contact-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                <Icons.MdWhatsapp className='cards-item-icon' />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-varela-round fw-600'>WhatsApp</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center">
                                <Link to="https://wa.me/212600456348" className='d-flex-center nav-link btn cards-item-btn ff-varela-round fw-600'>Contacter</Link>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-12 d-flex-center flex-wrap contact-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                <Icons.BsTelephone className='cards-item-icon' />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-varela-round fw-600'>Téléphone</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center">
                                <Link to="tel:0600456348" className='d-flex-center nav-link cards-item-tel ff-varela-round fw-600'>0600456348</Link>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-12 d-flex-center flex-wrap contact-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                <Icons.BsChatLeftText className='cards-item-icon' />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-varela-round fw-600'>Réseaux Sociaux</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center">
                                <div className="sociall d-inline-flex">
                                    <Link to='https://www.facebook.com/' className='nav-link'>
                                        <Icons.CiFacebook className='cards-item-social-icon cards-item-social-icon-fb' />
                                        {/* <img src={facebook} alt="facebook" className='cards-item-social-image' /> */}
                                    </Link>
                                    {/* <Link to='https://www.instagram.com/' className='nav-link'>
                                        <Icons.BsInstagram className='cards-item-social-icon' />
                                    </Link> */}
                                    {/* <img src={instagram} alt="instagram" className='cards-item-social-image' /> */}
                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </div>

            <div className="container contact-container d-flex-center pt-5 ox-hidden">

                <form action="" onSubmit={handleSubmit} className='d-flex-center'>
                    <div className="contact-form">
                        <div className="row d-flex-start">

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                                <formHelper.FormInput
                                    label="Nom Complet"
                                    spanValue="Nom Complet"
                                    name="name"
                                    value={values.name}
                                    className={touched.name && errors.name ? "is-invalid" : ""}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                                <formHelper.FormInput
                                    label="Adresse E-Mail"
                                    spanValue="Adresse E-Mail"
                                    name="email"
                                    type="email"
                                    value={values.email}
                                    className={touched.email && errors.email ? "is-invalid" : ""}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                                <formHelper.FormInput
                                    label="Sujet"
                                    spanValue="Sujet"
                                    name="subject"
                                    value={values.subject}
                                    className={touched.subject && errors.subject ? "is-invalid" : ""}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                                <formHelper.FormInput
                                    label="Message"
                                    spanValue="Message"
                                    name="message"
                                    textarea={true}
                                    rows={6}
                                    value={values.message}
                                    className={touched.message && errors.message ? "is-invalid" : ""}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>

                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-2 d-flex-start">
                                {
                                    isLoading ?
                                        <loadingHelper.DottedLoading />
                                        :
                                        <SubmitButton
                                            className="mt-1 px-2rem py-14px"
                                            btnLabel="Envoyer"
                                        />
                                }
                            </div>

                        </div>

                    </div>
                </form>

            </div>

        </Layout>
    );
};

export default Contact;